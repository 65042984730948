<template>
  <v-container
    class="home"
    fluid
  >
    <v-list
      class="pt-0"
      flat
    >

      <v-text-field
        v-model="newItem"
        xclick:append="addItem"
        @keyup.enter="addItem('1234-5678')"
        class="pa-3"
        outlined
        label="Add Item"
        append-icon="mdi-microphone"
        append-to="/itemsearch"
        hide-details
        clearable
      ></v-text-field>

      <v-subheader>PARENT GROUP 1</v-subheader>

      <v-list-item-group
        v-model="selectedItem"
      >    
        <div
          v-for="item in items"
          :key="item.id"
        >
          <v-list-item
            @click="doneTask(item.id)"
            :class="{ 'blue lighten-5' : item.done }"
          >
            <template v-slot:default>
              <v-list-item-action
                :class="{ 'd-none' : !mode }"
              >
                <v-checkbox
                  :input-value="item.done"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-avatar
              tile
              >
                <v-img 
                  :src="item.image"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content
                class="pl-3"
              >
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    SKU: {{ item.sku }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn 
                  @click.stop="decItem(item.id)"
                  icon
                >
                  <v-icon color="primary">mdi-minus</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-chip>{{ item.count }}</v-chip>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn 
                  @click.stop="incItem(item.id)"
                  icon
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action
                :class="{ 'd-none' : !mode }"
              >
                <v-btn 
                  @click.stop="deleteItem(item.id)"
                  icon
                >
                  <v-icon 
                    color="primary"
                  >mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list-item-group>

    </v-list>
    <v-fab-transition>
      <v-btn
        color="primary"
        dark
        absolute
        bottom
        right
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    
  </v-container>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      mode: 0,
      newItem: '',
      items: [
        {
          id: 1,
          sku: '001-0101',
          sku_parent: 'tutu',
          title: 'Small Pink Tutu',
          image: '/items/tutu.png',
          count: 3
        },
        {
          id: 2,
          sku: '002-0101',
          sku_parent: 'tutu',
          title: 'Large Adult Green Tutu',
          image: '/items/xtutu.png',
          count: 1
        },
        {
          id: 3,
          sku: '001-0301',
          sku_parent: 'tutu',
          title: 'Small Girl Black Tutu',
          image: '/items/tutu.png',
          count: 12
        }
      ]
    }
  },
  methods: {
    addItem(sku) {
      let newItem = {
        id: Date.now(),
        title: this.newItem,
        sku: sku,
        count: 1
      }
      this.items.push(newItem)
      this.newItem = ''
    },
    incItem(id) {
      let item = this.items.filter(item => item.id === id)[0]
      item.count++
    },
    decItem(id) {
      let item = this.items.filter(item => item.id === id)[0]
      item.count--
      if ( item.count < 1 )
        item.count = 1
    },
    doneTask(id) {
      let item = this.items.filter(item => item.id === id)[0]
      item.done = !item.done
    },
    deleteItem(id) {
      //this.items = this.items.filter(item => item.id !== id)
    },
    fetchBatch: function () {
      const baseURI = 'https://jsonplaceholder.typicode.com/users'
      this.$http.get(baseURI)
      .then((result) => {
        this.items = result.data
        console.log( result.data )
      })
    }    
  }
}
</script>
